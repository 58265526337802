exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-art-art-json-json-id-js": () => import("./../../../src/pages/art/{ArtJson.jsonId}.js" /* webpackChunkName: "component---src-pages-art-art-json-json-id-js" */),
  "component---src-pages-art-js": () => import("./../../../src/pages/art.js" /* webpackChunkName: "component---src-pages-art-js" */),
  "component---src-pages-games-games-json-json-id-js": () => import("./../../../src/pages/games/{GamesJson.jsonId}.js" /* webpackChunkName: "component---src-pages-games-games-json-json-id-js" */),
  "component---src-pages-games-js": () => import("./../../../src/pages/games.js" /* webpackChunkName: "component---src-pages-games-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-presentations-js": () => import("./../../../src/pages/presentations.js" /* webpackChunkName: "component---src-pages-presentations-js" */),
  "component---src-pages-presentations-presentations-json-json-id-js": () => import("./../../../src/pages/presentations/{PresentationsJson.jsonId}.js" /* webpackChunkName: "component---src-pages-presentations-presentations-json-json-id-js" */),
  "component---src-pages-software-js": () => import("./../../../src/pages/software.js" /* webpackChunkName: "component---src-pages-software-js" */),
  "component---src-pages-tech-js": () => import("./../../../src/pages/tech.js" /* webpackChunkName: "component---src-pages-tech-js" */),
  "component---src-pages-tech-tech-json-json-id-js": () => import("./../../../src/pages/tech/{TechJson.jsonId}.js" /* webpackChunkName: "component---src-pages-tech-tech-json-json-id-js" */),
  "component---src-pages-websites-js": () => import("./../../../src/pages/websites.js" /* webpackChunkName: "component---src-pages-websites-js" */),
  "component---src-pages-websites-websites-json-json-id-js": () => import("./../../../src/pages/websites/{WebsitesJson.jsonId}.js" /* webpackChunkName: "component---src-pages-websites-websites-json-json-id-js" */)
}

